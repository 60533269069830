import React from 'react'
import renderBloks from '@renderBloks'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import { Box, Button } from '@material-ui/core'
import { BackgroundImage } from '@system'
import ContentContainer from '@system/content-container'
import font from '@helpers/font'
import isInBrowser from '@helpers/is-in-browser'
import KeyboardArrowDownIcon from '@react-svgs/keyboard_arrow_down.svg'
import { isImage } from './device-frame-module/shared'

const { makeResponsiveFontSizes } = font

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '550px',
    height: '60vh',
    maxHeight: '1000px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '10px',
      height: '60vh',
      maxHeight: '500px',
    },
  },
  backgroundImage: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    right: 0,
    top: 0,
    left: 0,
  },
  subAndCtaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: (props) => (props.circleCta ? 'column-reverse' : 'column'),
    marginBottom: '100px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '-100vh',
    width: '100vw',
    height: '100vh',
  },
  largeTitle: {
    ...makeResponsiveFontSizes(200),
    color: '#fff',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: '1.2',
    zIndex: 10,
  },
  subtitle: {
    width: '75%',
    ...makeResponsiveFontSizes(36),
    color: theme.palette.text.white,
    textAlign: 'center',
    marginBottom: '2.5rem',
    marginTop: '0.5rem',
    zIndex: 10,
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      margin: '0 1rem',
    },
    '& button': {
      margin: '0 1rem',
    },
    zIndex: 99,
  },
  whatsNew: {
    zIndex: 10,
    background:
      'linear-gradient(180deg, rgba(24, 47, 78, 0) 100%, #184276 17.6%)',
    bottom: '0',
    color: theme.palette.text.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noTextDecoration: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    borderBottom: 'none',
  },
  whatsNewText: {
    ...makeResponsiveFontSizes(30),
    marginBottom: '0.75rem',
  },
  downArrow: {
    width: '2.7rem',
    height: '2.7rem',
    border: '3px solid white',
    borderRadius: '50%',
  },
  outerContainer: {
    minWidth: '100%',
  },
  video: {
    '& >video': {
      objectFit: 'cover',
      objectPosition: 'top',
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      right: 0,
      top: 0,
      left: 0,
    },
    width: '100vw',
    height: '100vh',
  },
}))

const ForegroundContent = (props) => {
  const { largeTitle, subtitle, ctas } = props.blok
  const classes = useStyles(props.blok)
  return (
    <ContentContainer className={classes.contentContainer}>
      <Box className={classes.largeTitle}>{largeTitle}</Box>
      <Box className={classes.subAndCtaContainer}>
        <Box className={classes.subtitle}>{subtitle}</Box>
        <Box className={classes.ctaContainer}>{renderBloks(ctas)}</Box>
      </Box>
      <Box className={classes.whatsNew}>
        <Button
          className={classes.noTextDecoration}
          activeClassName="active"
          partiallyActive={false}
          onClick={(e) => {
            e.preventDefault()
            let pageHeight = window.innerHeight
            if (isInBrowser) {
              window.scrollBy({ top: pageHeight, left: 0, behavior: 'smooth' })
            }
          }}
        >
          <KeyboardArrowDownIcon
            className={classes.downArrow}
          ></KeyboardArrowDownIcon>
        </Button>
      </Box>
    </ContentContainer>
  )
}

const BackgroundVideo = (props, classes, video) => (
  <Box className={classes.outerContainer}>
    <Box className={classes.video}>
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        preload={true}
        poster={props.blok.backgroundImage}
      >
        Sorry your browser does not support HTML5 video
        <source type="video/mp4" src={video} />
      </video>
    </Box>
    <ForegroundContent {...props} />
  </Box>
)

const FullScreenHero = (props) => {
  const { backgroundImage } = props.blok
  const classes = useStyles(props.blok)
  const background = !!backgroundImage && backgroundImage
  const bgIsImage = isImage(backgroundImage)

  return (
    <SbEditable content={props.blok}>
      {bgIsImage ? (
        <Box className={classes.outerContainer}>
          <BackgroundImage
            className={classes.backgroundImage}
            image={background}
          ></BackgroundImage>
          <ForegroundContent {...props} />
        </Box>
      ) : (
        BackgroundVideo(props, classes, backgroundImage)
      )}
    </SbEditable>
  )
}

export default FullScreenHero
